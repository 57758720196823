$filter-font-size: 0.75rem;
.filter {
  .form-check {
    font-size: $filter-font-size;
    min-height: 1.3rem;
  }
  .form-check-label {
    display: inline-block;
    overflow: hidden;
    padding-left: 0;
    text-overflow: ellipsis;
    white-space: break-spaces;
    width: 100%;
  }
}
