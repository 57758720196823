.variant {
  background-color: #ffffff;
  border: 1px solid #d7e1eb;
  border-radius: 3px;
  margin: auto;
  display: inline-block;
  text-align: right;
  position: relative;

  .loading {
    width: 300px;
    height: 300px;
    position: relative;
    text-align: center;

    .spinner {
      position: absolute;
      top: 50%;
    }
  }

  .info {
    line-height: 12px;
    margin-right: 2px;
    &-display {
      margin-bottom: 1px;
    }
    &-rich_media {
      margin-bottom: -6px;
    }
  }

  .video {
    max-width: 100%;
  }

  .footer {
    padding: 0.1rem 0.2rem;
  }
}
