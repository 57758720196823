.main {
  gap: 1rem;

  &.auto {
    display: flex;
    flex-wrap: wrap;
  }

  &.columns {
    display: grid;
    justify-items: center;
  }

  .no-results {
    font-style: italic;
    padding: 1rem;
    text-align: left;
  }
}
