.preview {
  overflow: hidden;
  padding: 0 !important;
}

.hide-preview {
  display: none;
  height: 0;
  width: 0;
}
