.general-message {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.project-title {
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 0 !important;
  width: fit-content !important;
}

.second-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 !important;

  .project-subtitle-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    height: max-content;

    .subtitle {
      font-size: 1.25rem;
      font-weight: 600;
      overflow-wrap: anywhere;
    }
  }

  .options-container {
    align-items: flex-end;
    display: flex;
    margin-left: auto;

    .options {
      align-items: center;
      display: flex;
      gap: 1rem;
      height: 3rem;
      margin-right: 2rem;

      .columns-toggle {
        height: 100%;

        label {
          align-items: center;
          display: flex;
        }
      }
    }
  }
}
